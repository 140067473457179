.grid-container {
    position: relative;
    background-image: linear-gradient(to right, #ddd 1px, transparent 1px),
                      linear-gradient(to bottom, #ddd 1px, transparent 1px);
    background-size: 200px 200px; /* Adjust this to match the rowHeight and column width */
    height: 100vh;
  }
  
  .grid-item {
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333;
  }

