/* Targeting the container with class "scrollable-container" */
.data-sidebar::-webkit-scrollbar {
    width: 5px!important; /* Width of the scrollbar */
}

/* Track styles */
.data-sidebar::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
    width: 5px!important;
}

/* Handle styles */
.data-sidebar::-webkit-scrollbar-thumb {
    background: #D0D5DD; /* Color of the scrollbar handle */
    border-radius: 6px !important; /* Border radius of the handle */
   
}

/* Handle on hover */
.data-sidebar::-webkit-scrollbar-thumb:hover {
    background: #a3a2a2; /* Color of the scrollbar handle on hover */
}

