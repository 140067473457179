
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');




.container{
    width: 100%;
    height: 100vh;
    background-color: black;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}


.text-gradient {
  background: linear-gradient(to right, #d3c3f4, #5500ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Ruby */
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 346.8 77.2% 49.8%;
    --primary-foreground: 355.7 100% 99%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 45%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 72% 51%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 346.8 77.2% 49.8%;
    --chart-1: 347 77% 50%;
    --chart-2: 352 83% 91%;
    --chart-3: 350 80% 72%;
    --chart-4: 351 83% 82%;
    --chart-5: 349 77% 62%;
  }
  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 346.8 77.2% 49.8%;
    --primary-foreground: 355.7 100% 99%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 72% 51%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 221.2 83.2% 53.3%;
    --chart-1: 347 77% 50%;
    --chart-2: 349 77% 62%;
    --chart-3: 350 80% 72%;
    --chart-4: 351 83% 82%;
    --chart-5: 352 83% 91%;
    }
}



/* Daylight */
/* @layer base {
  :root {
    --background: 36 39% 88%;
    --foreground: 36 45% 15%;
    --primary: 36 45% 70%;
    --primary-foreground: 36 45% 11%;
    --secondary: 40 35% 77%;
    --secondary-foreground: 36 45% 25%;
    --accent: 36 64% 57%;
    --accent-foreground: 36 72% 17%;
    --destructive: 0 84% 37%;
    --destructive-foreground: 0 0% 98%;
    --muted: 36 33% 75%;
    --muted-foreground: 36 45% 25%;
    --card: 36 46% 82%;
    --card-foreground: 36 45% 20%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --border: 36 45% 60%;
    --input: 36 45% 60%;
    --ring: 36 45% 30%;
    --chart-1: 25 34% 28%;
    --chart-2: 26 36% 34%;
    --chart-3: 28 40% 40%;
    --chart-4: 31 41% 48%;
    --chart-5: 35 43% 53%;
  }
  .dark {
    --background: 36 39% 88%;
    --foreground: 36 45% 15%;
    --primary: 36 45% 70%;
    --primary-foreground: 36 45% 11%;
    --secondary: 40 35% 77%;
    --secondary-foreground: 36 45% 25%;
    --accent: 36 64% 57%;
    --accent-foreground: 36 72% 17%;
    --destructive: 0 84% 37%;
    --destructive-foreground: 0 0% 98%;
    --muted: 36 33% 75%;
    --muted-foreground: 36 45% 25%;
    --card: 36 46% 82%;
    --card-foreground: 36 45% 20%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --border: 36 45% 60%;
    --input: 36 45% 60%;
    --ring: 36 45% 30%;
    --chart-1: 25 34% 28%;
    --chart-2: 26 36% 34%;
    --chart-3: 28 40% 40%;
    --chart-4: 31 41% 48%;
    --chart-5: 35 43% 53%;
    }
} */