.border-stroke{
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%235925DCFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 8px;
}

 .dashboard-box:hover > .options{
    /* opacity: 100; */
    transition: all 0.3s ease;
    display: flex;
    width: 50px;
}

.dashboard-box:hover{
    width: 280px;
    transition: all 0.3s ease;
}

.dashboard-box{
    /* width: 220px; */
    transition: all 0.3s ease;
}

/* .dashboard-box{
    position: relative;
} */
.options{
    /* opacity: 0; */
    width: 0px;
    /* transition: all 0.5s ease; */
    display: none;
   
} 